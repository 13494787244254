import { ReactElement } from 'react';
import { SxProps, Theme } from '@mui/material';
import { faCompress, faExpand } from '@fortawesome/pro-regular-svg-icons';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';
import { IconButton } from './IconButton';

export type FullScreenButtonProps = {
  isFullScreen: boolean;
  sx?: SxProps<Theme>;
  onClick?: (next: boolean) => void;
};

export const FullScreenButton = ({
  isFullScreen,
  sx,
  onClick,
}: FullScreenButtonProps): ReactElement => (
  <IconButton
    aria-label="full-screen"
    sx={sx}
    onClick={() => onClick?.(!isFullScreen)}
  >
    <FaIcon icon={isFullScreen ? faCompress : faExpand} />
  </IconButton>
);
