import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

type Props = {
  value: number;
  onChange: (rate: number) => void;
  plain?: boolean;
  width?: number | string;
};

const OPTIONS = [0.5, 0.75, 1, 1.5, 2];

const PlainInput = styled(InputBase)<{ plain?: boolean }>`
  & .MuiInputBase-input {
    background-color: transparent;
    padding: ${(props) => props.theme.spacing(1)} 0;
  }
`;

const PlaybackRateMenu = ({
  value,
  onChange,
  plain,
  width,
}: Props): JSX.Element => {
  const handleSelect = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl size="small">
      <Select
        aria-label="playback rate"
        value={value}
        onChange={handleSelect}
        input={plain ? <PlainInput /> : undefined}
        sx={{
          typography: 'h5',
          width,
          '& .MuiOutlinedInput-input': {
            py: 0.7,
          },
        }}
        MenuProps={{
          disableAutoFocusItem: true,
        }}
      >
        {OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}x
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PlaybackRateMenu;
