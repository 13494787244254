import { ReactElement, ReactNode } from 'react';
import * as React from 'react';
import { Box, Chip, ChipProps, Theme } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FaIcon } from '../Icon/FaIcon';

export interface SrFilterChipsProps {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

export const SrFilterChips = ({
  sx,
  children,
}: SrFilterChipsProps): ReactElement => {
  return <Box sx={sx}>{children}</Box>;
};

/**
 * Chip to display the active filter option
 */
export type SrFilterChipsItemProps = ChipProps & {
  value: string;
  onDelete?: (v: string) => void;
};

const Item = styled(Chip)(({ theme }) => ({
  background: 'none',
  borderColor: theme.palette.grey.A400,
  ...theme.typography.body2,
  textAlign: 'left',
  color: theme.palette.grey.A700,
  margin: '0 10px 8px 0',
  padding: '14px 8px 12px !important',
}));

const SrFilterChipsItem = ({
  label,
  value,
  variant,
  sx,
  onDelete,
}: SrFilterChipsItemProps): React.JSX.Element => {
  return (
    <Item
      key={value}
      label={label || value}
      variant={variant || 'outlined'}
      sx={sx}
      deleteIcon={
        <FaIcon
          icon={faXmark}
          sx={{
            fontSize: '15px !important',
            m: '0 4px 1px 0 !important',
            fill: (theme) => theme.palette.grey.A700,
          }}
        />
      }
      onDelete={onDelete}
    />
  );
};

SrFilterChips.Item = SrFilterChipsItem;
