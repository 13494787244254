// wrap js window object
interface CustomWindow extends Window {
  ENV: RuntimeConfig;
  ENV_ES: RuntimeConfigEs;
}

declare let window: CustomWindow;

// declare environment variables
const RunTimeVariable = {
  ADMIN_BASE_URL: 'ADMIN_BASE_URL',
  AUTH0_AUDIENCE: 'AUTH0_AUDIENCE',
  AUTH0_CALLBACK_ROUTE: 'AUTH0_CALLBACK_ROUTE',
  AUTH0_DATABASE_CONNECTION_NAME: 'AUTH0_DATABASE_CONNECTION_NAME',
  AUTH0_DOMAIN: 'AUTH0_DOMAIN',
  AUTH0_LEGACY_CLAIMS_NAMESPACE: 'AUTH0_LEGACY_CLAIMS_NAMESPACE',
  AUTH0_SCOPES: 'AUTH0_SCOPES',
  AUTO_UPDATE_INTERVAL: 'AUTO_UPDATE_INTERVAL',
  CDN_FQDN: 'CDN_FQDN',
  CLOUDINARY_CLOUD_NAME: 'CLOUDINARY_CLOUD_NAME',
  CLOUDINARY_FOLDER_NAME: 'CLOUDINARY_FOLDER_NAME',
  COMPANION_URL: 'COMPANION_URL',
  CONCEPT_BASE_URL: 'CONCEPT_BASE_URL',
  COPILOT_BASE_URL: 'COPILOT_BASE_URL',
  CREATOR_BASE_URL: 'CREATOR_BASE_URL',
  CUSTOMER_BASE_URL: 'CUSTOMER_BASE_URL',
  ENVIRONMENT: 'ENVIRONMENT',
  IMAGE_TAG: 'IMAGE_TAG',
  ORDER_BASE_URL: 'ORDER_BASE_URL',
  PRODUCTION_BASE_URL: 'PRODUCTION_BASE_URL',
  PROJECT_BASE_URL: 'PROJECT_BASE_URL',
  POLICY_BASE_URL: 'POLICY_BASE_URL',
  RENDER_API_BASE_URL: 'RENDER_API_BASE_URL',
  REVISION_BASE_URL: 'REVISION_BASE_URL',
  S3_ENDPOINT: 'S3_ENDPOINT',
  SEARCH_INDEX_PREFIX: 'SEARCH_INDEX_PREFIX',
  SEARCH_URL: 'SEARCH_URL',
  SEGMENT_WRITE_KEY: 'SEGMENT_WRITE_KEY',
  SENTRY_ENABLED: 'SENTRY_ENABLED',
  SPECIFICATION_BASE_URL: 'SPECIFICATION_BASE_URL',
  STORAGE_BASE_URL: 'STORAGE_BASE_URL',
  SUPERGRAPH_BASE_URL: 'SUPERGRAPH_BASE_URL',
  TIP_TAP_AI_CONFIG: 'TIP_TAP_AI_CONFIG',
  USAGE_BASE_URL: 'USAGE_BASE_URL',
} as const;

// declare environment variables
const RunTimeVariableEs = {
  AUTH0_CLIENT_ID: 'AUTH0_CLIENT_ID',
  LAUNCHDARKLY_CLIENT_ID: 'LAUNCHDARKLY_CLIENT_ID',
  SEARCH_API_KEY: 'SEARCH_API_KEY',
  SEARCH_ANONYMOUS_ACCESS_TOKEN: 'SEARCH_ANONYMOUS_ACCESS_TOKEN',
} as const;

type TipTapAiConfig = {
  /** should be a supported OpenAITextModel, see:
   * - https://tiptap.dev/docs/editor/ai/extension/commands#text-options
   * - @tiptap-pro/extension-ai/dist/tiptap-pro/packages/extension-ai/src/types.d.ts
   */
  modelName?: string;
  conceptIdeaPrompt?: string;
  optimizeBriefPrompt?: string;
};

type RuntimeConfig = {
  ADMIN_BASE_URL: string;
  AUTH0_AUDIENCE: string;
  AUTH0_CALLBACK_ROUTE: string;
  AUTH0_DATABASE_CONNECTION_NAME: string;
  AUTH0_DOMAIN: string;
  AUTH0_LEGACY_CLAIMS_NAMESPACE: string;
  AUTH0_SCOPES: string;
  AUTO_UPDATE_INTERVAL: number | false | undefined;
  CDN_FQDN: string;
  CLOUDINARY_CLOUD_NAME: string;
  CLOUDINARY_FOLDER_NAME: string;
  COMPANION_URL: string;
  CONCEPT_BASE_URL: string;
  COPILOT_BASE_URL: string;
  CREATOR_BASE_URL: string;
  CUSTOMER_BASE_URL: string;
  SEARCH_URL: string;
  ENVIRONMENT: string;
  IMAGE_TAG: string;
  ORDER_BASE_URL: string;
  PRODUCTION_BASE_URL: string;
  PROJECT_BASE_URL: string;
  POLICY_BASE_URL: string;
  RENDER_API_BASE_URL: string;
  REVISION_BASE_URL: string;
  S3_ENDPOINT: string;
  SEARCH_INDEX_PREFIX: string | undefined;
  SEGMENT_WRITE_KEY: string;
  SENTRY_ENABLED: boolean;
  SPECIFICATION_BASE_URL: string;
  STORAGE_BASE_URL: string;
  SUPERGRAPH_BASE_URL: string;
  TIP_TAP_AI_CONFIG: TipTapAiConfig;
  USAGE_BASE_URL: string;
};

type RuntimeConfigEs = {
  AUTH0_CLIENT_ID: string;
  LAUNCHDARKLY_CLIENT_ID: string;
  SEARCH_API_KEY: string;
  SEARCH_ANONYMOUS_ACCESS_TOKEN: string;
};

const apiUrl = (name: string, fallback: string) =>
  process.env.BACKEND_PROXY || !fallback
    ? `${window.location.protocol}//${[window.location.host, name].filter(Boolean).join('/')}`
    : fallback;

/** Centralises the way we retrieve environment configuration throughout the app */
export const ConfigService = Object.freeze({
  ADMIN_BASE_URL: () => window.ENV[RunTimeVariable.ADMIN_BASE_URL],
  APP_URL: () => window.location.origin,
  APP_VERSION: () => window.ENV[RunTimeVariable.IMAGE_TAG] || 'unknown',
  AUTH0_AUDIENCE: () => window.ENV[RunTimeVariable.AUTH0_AUDIENCE],
  AUTH0_CALLBACK_ROUTE: () => window.ENV[RunTimeVariable.AUTH0_CALLBACK_ROUTE],
  AUTH0_CLIENT_ID: () => window.ENV_ES[RunTimeVariableEs.AUTH0_CLIENT_ID],
  AUTH0_DATABASE_CONNECTION_NAME: () =>
    window.ENV[RunTimeVariable.AUTH0_DATABASE_CONNECTION_NAME],
  AUTH0_DOMAIN: () => window.ENV[RunTimeVariable.AUTH0_DOMAIN],
  AUTH0_LEGACY_CLAIMS_NAMESPACE: () =>
    window.ENV[RunTimeVariable.AUTH0_LEGACY_CLAIMS_NAMESPACE],
  AUTH0_SCOPES: () => window.ENV[RunTimeVariable.AUTH0_SCOPES] || '',
  AUTO_UPDATE_INTERVAL: () => window.ENV[RunTimeVariable.AUTO_UPDATE_INTERVAL],
  CLOUDINARY_CLOUD_NAME: () =>
    window.ENV[RunTimeVariable.CLOUDINARY_CLOUD_NAME],
  CLOUDINARY_FOLDER_NAME: () =>
    window.ENV[RunTimeVariable.CLOUDINARY_FOLDER_NAME] || 'remote_media',
  COMPANION_URL: () => window.ENV[RunTimeVariable.COMPANION_URL],
  CONCEPT_BASE_URL: () =>
    apiUrl('concept', window.ENV[RunTimeVariable.CONCEPT_BASE_URL]),
  COPILOT_BASE_URL: () =>
    apiUrl('copilot', window.ENV[RunTimeVariable.COPILOT_BASE_URL]),
  CREATOR_BASE_URL: () =>
    apiUrl('creator', window.ENV[RunTimeVariable.CREATOR_BASE_URL]),
  CUSTOMER_BASE_URL: () =>
    apiUrl('customer', window.ENV[RunTimeVariable.CUSTOMER_BASE_URL]),
  ENVIRONMENT: () => window.ENV[RunTimeVariable.ENVIRONMENT],
  LAUNCHDARKLY_CLIENT_ID: () =>
    window.ENV_ES[RunTimeVariableEs.LAUNCHDARKLY_CLIENT_ID],
  ORDER_BASE_URL: () =>
    apiUrl('order', window.ENV[RunTimeVariable.ORDER_BASE_URL]),
  PRODUCTION_BASE_URL: () =>
    apiUrl('production', window.ENV[RunTimeVariable.PRODUCTION_BASE_URL]),
  PROJECT_BASE_URL: () =>
    apiUrl('project', window.ENV[RunTimeVariable.PROJECT_BASE_URL]),
  POLICY_BASE_URL: () =>
    apiUrl('policy', window.ENV[RunTimeVariable.POLICY_BASE_URL]),
  RENDER_API_BASE_URL: () =>
    apiUrl('render', window.ENV[RunTimeVariable.RENDER_API_BASE_URL]),
  REVISION_BASE_URL: () =>
    apiUrl('revision', window.ENV[RunTimeVariable.REVISION_BASE_URL]),
  S3_ENDPOINT: () => window.ENV[RunTimeVariable.S3_ENDPOINT],
  SEARCH_API_KEY: () => window.ENV_ES[RunTimeVariableEs.SEARCH_API_KEY],
  SEARCH_ANONYMOUS_ACCESS_TOKEN: () =>
    window.ENV_ES[RunTimeVariableEs.SEARCH_ANONYMOUS_ACCESS_TOKEN],
  SEARCH_INDEX_PREFIX: () => window.ENV[RunTimeVariable.SEARCH_INDEX_PREFIX],
  SEARCH_URL: () => window.ENV[RunTimeVariable.SEARCH_URL],
  SEGMENT_WRITE_KEY: () => window.ENV[RunTimeVariable.SEGMENT_WRITE_KEY],
  SENTRY_ENABLED: () => window.ENV[RunTimeVariable.SENTRY_ENABLED],
  SPECIFICATION_BASE_URL: () =>
    apiUrl('specification', window.ENV[RunTimeVariable.SPECIFICATION_BASE_URL]),
  STORAGE_BASE_URL: () =>
    apiUrl('storage', window.ENV[RunTimeVariable.STORAGE_BASE_URL]),
  SUPERGRAPH_BASE_URL: () =>
    apiUrl('', window.ENV[RunTimeVariable.SUPERGRAPH_BASE_URL]),
  TIP_TAP_AI_CONFIG: () => window.ENV[RunTimeVariable.TIP_TAP_AI_CONFIG],
  USAGE_BASE_URL: () =>
    apiUrl('usage', window.ENV[RunTimeVariable.USAGE_BASE_URL]),
});
