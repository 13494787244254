import { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { FullScreenButton } from '../MediaControl/FullScreenButton';

export type StaticMediaControlsProps = {
  fullScreenMode: boolean;
  onClickFullscreen: () => void;
};

export const StaticMediaControls = ({
  fullScreenMode,
  onClickFullscreen,
}: StaticMediaControlsProps): ReactElement => (
  <>
    <Divider
      sx={{
        mt: 1,
        mx: 2,
        mb: { xs: 3, md: 6 },
        borderBottomWidth: 6,
        borderRadius: 1,
        display: { xs: 'none', sm: 'block' },
      }}
    />
    <Stack
      direction="row"
      justifySelf="flex-end"
      flex={{ sm: 1 }}
      justifyContent={{ sm: 'flex-end' }}
      p={{ xs: fullScreenMode ? 2 : 1, sm: 2 }}
      pt={{ sm: 0 }}
      // TODO: Revisit Fullscreen separately
      visibility="hidden"
    >
      <FullScreenButton
        isFullScreen={fullScreenMode}
        onClick={onClickFullscreen}
      />
    </Stack>
  </>
);
