import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { QueryParamProvider as BaseProvider } from 'use-query-params';
import { Location, useLocation, useNavigate } from 'react-router-dom';

type PushReplaceHistory = {
  push(location: Location): void;
  replace(location: Location): void;
};

type QueryParamProviderRouteProps = {
  children?(props: {
    history: PushReplaceHistory;
    location: Location;
  }): ReactElement;
};

const QueryParamProviderRoute = ({
  children,
}: QueryParamProviderRouteProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const history = useMemo(
    () => ({
      replace(newLocation: Location) {
        navigate(newLocation, { replace: true, state: newLocation.state });
      },
      push(newLocation: Location) {
        navigate(newLocation, { replace: false, state: newLocation.state });
      },
    }),
    [navigate],
  );

  if (typeof children !== 'function') {
    throw new Error(
      `${typeof children} is not a function. QueryParamProviderRoute requires a render prop as a child`,
    );
  }

  return children({ history, location });
};

export const QueryParamProvider = ({ children }: PropsWithChildren) => (
  <BaseProvider ReactRouterRoute={QueryParamProviderRoute}>
    {children}
  </BaseProvider>
);
