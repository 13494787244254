import { Box } from '@mui/material';
import some from 'lodash/some';
import { useLayoutEffect, useState, useRef } from 'react';
import { useClickOutside } from '@react-hookz/web';

import { MarkType, MarkGroupType } from './type';
import {
  COLLAPSED_MARK_SIZE,
  COLLAPSED_MARK_GAP,
  GROUP_PADDING,
  MARK_SIZE,
  MAX_MARK_PER_GROUP,
  EXPANDED_MARK_GAP,
} from './constants';
import getExpandedLeftPosition from './getExpandedLeftPosition';

type Props = {
  group: MarkGroupType;
  onClickMark?: (mark: MarkType) => void;
  wrapperWidth: number;
};

const StackedMark = ({
  group,
  onClickMark,
  wrapperWidth,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setExpanded(some(group.marks, 'active'));
  }, [group.marks]);

  const handleClickGroup = () => {
    setExpanded(true);
  };

  const handleClickMark = (mark: MarkType) => () => {
    if (!expanded) return;
    onClickMark?.(mark);
  };

  useClickOutside(ref, () => {
    setExpanded(false);
  });

  const marksToDisplay: MarkType[] = expanded
    ? group.marks
    : group.marks.slice(0, MAX_MARK_PER_GROUP);

  return (
    <Box
      ref={ref}
      onClick={handleClickGroup}
      aria-label="stacked-marks"
      aria-expanded={expanded}
      role="button"
      sx={{
        display: 'flex',
        px: `${GROUP_PADDING}px`,
        gap: `${expanded ? EXPANDED_MARK_GAP : COLLAPSED_MARK_GAP}px`,
        alignItems: 'center',
        height: expanded ? 16.5 : MARK_SIZE,
        left: expanded
          ? getExpandedLeftPosition(group, wrapperWidth)
          : group.left,
        position: 'absolute',
        bgcolor: 'grey.400',
        borderRadius: MARK_SIZE / 2,
        cursor: 'pointer',
        boxShadow: expanded ? 2 : 0,
        zIndex: 1,
      }}
    >
      {marksToDisplay.map((mark) => {
        const inactiveColor = expanded ? 'grey.A200' : 'grey.200';
        return (
          <Box
            key={mark.id}
            aria-label="mark"
            data-testid={`mark-${mark.id}`}
            sx={{
              width: expanded ? MARK_SIZE : COLLAPSED_MARK_SIZE,
              height: expanded ? MARK_SIZE : COLLAPSED_MARK_SIZE,
              bgcolor: mark.active && expanded ? 'primary.main' : inactiveColor,
              borderRadius: '50%',
            }}
            onClick={handleClickMark(mark)}
          />
        );
      })}
    </Box>
  );
};

export default StackedMark;
