import { PropsWithChildren, ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * Component to set the HTML (document / tab) title.
 *
 * The effective title will be applied by the most deeply nested `HtmlTitle` component, if an `HtmlTitle` is un-mounted
 * the title will come from the next `HtmlTitle` up the tree
 *
 * @example
 * <HtmlTitle>Projects</HtmlTitle>
 */
export const HtmlTitle = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  );
};
