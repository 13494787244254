import { ReactElement } from 'react';
import * as React from 'react';
import {
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListProps,
  ListItemProps,
  ListSubheaderProps,
  ListItemAvatarProps,
  ListItemTextProps,
  Paper,
  PaperProps,
} from '@mui/material';

export const SrList = ({ children }: ListProps): ReactElement => {
  return <List sx={{ width: '100%' }}>{children}</List>;
};

/**
 * List Subheader to separate List Items into groups
 */
const SrListSubheader = ({
  children,
  sx,
}: ListSubheaderProps): React.JSX.Element => {
  return (
    <ListSubheader
      sx={[{ m: '12px 0 4px', p: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Typography variant="h5" color="grey.800">
        {children}
      </Typography>
    </ListSubheader>
  );
};

SrList.Subheader = SrListSubheader;

/**
 * List Item to display the row content
 */
const SrListItem = ({ children, sx }: ListItemProps): React.JSX.Element => {
  return (
    <ListItem
      sx={[
        { flexWrap: 'wrap', p: '4px 0' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </ListItem>
  );
};

SrList.Item = SrListItem;

/**
 * List Item's Icon
 */
const SrListItemIcon = ({
  children,
  sx,
}: ListItemAvatarProps): React.JSX.Element => {
  const iconSize = '42px';
  const avatarSize = '28px';

  return (
    <ListItemAvatar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid',
          borderColor: 'foreground.secondary',
          borderRadius: 1,
          bgcolor: 'white',
          cornerRadius: 1,
          width: iconSize,
          height: iconSize,
        }}
      >
        <Avatar
          variant="square"
          sx={[
            { width: avatarSize, height: avatarSize },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {children}
        </Avatar>
      </Box>
    </ListItemAvatar>
  );
};

SrList.ItemIcon = SrListItemIcon;

/**
 * List Item's Text
 */
const SrListItemText = (props: ListItemTextProps): React.JSX.Element => {
  return (
    <ListItemText
      {...props}
      sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
    >
      {props.primary}
      {props.secondary}
    </ListItemText>
  );
};

SrList.ItemText = SrListItemText;

/**
 * List Item's Note
 */
const SrListItemNote = ({ children, sx }: PaperProps): React.JSX.Element => {
  return (
    <Paper
      elevation={0}
      sx={[
        {
          m: '10px 0 0 0px',
          p: '8px 24px 8px 16px',
          width: 1,
          order: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Paper>
  );
};

SrList.ItemNote = SrListItemNote;
