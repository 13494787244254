import {
  Box,
  CircularProgress as BaseCircularProgress,
  CircularProgressProps as BaseCircularProgressProps,
  circularProgressClasses,
} from '@mui/material';
import { ReactElement } from 'react';

export type CircularProgressProps = {
  barBackgroundColor?: string;
} & BaseCircularProgressProps;

export const CircularProgress = ({
  barBackgroundColor,
  ...baseProps
}: CircularProgressProps): ReactElement => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <BaseCircularProgress
        {...baseProps}
        sx={{
          color: barBackgroundColor || 'grey.A200',
        }}
        variant="determinate"
        value={100}
      />
      <BaseCircularProgress
        sx={{
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        {...baseProps}
      />
    </Box>
  );
};
