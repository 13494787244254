/* eslint-disable @typescript-eslint/no-use-before-define */
import { ConfigService } from '@shuttlerock/configuration';
import {
  Auth0Client,
  GetTokenSilentlyOptions,
  User as Auth0User,
} from '@auth0/auth0-spa-js';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import type { User as AppUser } from '@shuttlerock/auth-types';
import { UserRole } from '@shuttlerock/auth-types';
import { getUserData } from './getUserData';

/** @internal - Not to be used outside `@creative-foundation/auth` */
const Auth0 = new Auth0Client({
  clientId: ConfigService.AUTH0_CLIENT_ID(),
  domain: ConfigService.AUTH0_DOMAIN(),
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    audience: ConfigService.AUTH0_AUDIENCE(),
    redirect_uri: `${ConfigService.APP_URL()}${ConfigService.AUTH0_CALLBACK_ROUTE()}`,
    scope: `openid profile email ${ConfigService.AUTH0_SCOPES()}`,
  },
});

/** @internal - Not to be used outside `@creative-foundation/auth` */
const isAuthClientInitialized = Auth0.checkSession().then(() => true);

// The typing gets complex with `detailedResponse` so we can look to support **if** we ever have a use-case for it
export type GetTokenOptions = Omit<GetTokenSilentlyOptions, 'detailedResponse'>;
type Listener = (user: AppUser | undefined) => void;

/** @internal - Not to be used outside `@creative-foundation/auth` */
export type AuthService = {
  getUser(): AppUser | undefined;
  subscribe(listener: Listener): () => void;
  getAccessTokenSilently(options?: GetTokenOptions): Promise<string>;
  loginWithRedirect: typeof Auth0.loginWithRedirect;
  logout: typeof Auth0.logout;
  handleRedirectCallback: typeof Auth0.handleRedirectCallback;
};

/** @internal - Not to be used outside `@creative-foundation/auth` */
export const AuthClient = ((): AuthService => {
  let user: AppUser | undefined;
  const listeners = new Set<Listener>();
  const userCache = new Map<string, AppUser>();

  function getUser() {
    return user;
  }

  async function getAccessTokenSilently(options?: GetTokenOptions) {
    await isAuthClientInitialized;
    const accessToken = await Auth0.getTokenSilently(options);
    if (userCache.has(accessToken)) {
      return accessToken;
    }

    // Prevent the cache from growing indefinitely, we only care about the most recent token.
    userCache.clear();

    const auth0User = await Auth0.getUser();
    user = await auth0UserToAppUser(auth0User, accessToken);
    listeners.forEach((listener) => listener(user));

    if (user) {
      userCache.set(accessToken, user);
    }

    return accessToken;
  }

  const logout: typeof Auth0.logout = (options) => Auth0.logout(options);
  const loginWithRedirect: typeof Auth0.loginWithRedirect = (options) =>
    Auth0.loginWithRedirect(options);
  const handleRedirectCallback: typeof Auth0.handleRedirectCallback = (url) =>
    Auth0.handleRedirectCallback(url);

  function subscribe(listener: Listener) {
    listeners.add(listener);
    return () => listeners.delete(listener);
  }

  return {
    getUser,
    subscribe,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    handleRedirectCallback,
  } as const;
})();

/** @internal - Not to be used outside `@creative-foundation/auth` */
export const useUser = () =>
  useSyncExternalStore(
    AuthClient.subscribe,
    AuthClient.getUser,
    AuthClient.getUser,
  );

/** @internal - Not to be used outside `@creative-foundation/auth` */
const INTERNAL_ROLES: readonly UserRole[] = Object.freeze([
  UserRole.SRC_Admin,
  UserRole.SRC_Art_Director,
  UserRole.SRC_Designer,
  UserRole.SRC_Manager,
  UserRole.SRC_Traffic,
]);

const AUTH0_USER_REGEX = /^auth0/;
const NAMESPACE = 'https://cloud.shuttlerock.com/identity/claims';

/** @internal - Not to be used outside `@creative-foundation/auth` */
export const ROLES_CLAIM = `${NAMESPACE}/roles`;

/** @internal - Not to be used outside `@creative-foundation/auth` */
export async function auth0UserToAppUser(
  auth0User: Auth0User | undefined,
  accessToken: string,
): Promise<AppUser | undefined> {
  if (!auth0User) {
    return undefined;
  }

  const userData = await getUserData(accessToken);
  const roles = (auth0User[ROLES_CLAIM] || []) as UserRole[];

  return {
    roles,
    legacyId: userData?.id,
    integrationKey: userData?.integrationKey,
    fullName: auth0User.name,
    firstName: auth0User.given_name,
    lastName: auth0User.family_name,
    email: auth0User.email,
    picture: auth0User.picture,
    hasInternalRole: !!roles.find((role) => INTERNAL_ROLES.includes(role)),
    auth0IntegrationKey: auth0User.sub,
    isSocialUser: auth0User.sub ? !AUTH0_USER_REGEX.test(auth0User.sub) : false,
  };
}
