import {
  ElementType,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Stack,
} from '@mui/material';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';
import { HelperTooltip } from '../FormControl/FormControl';

export type FormFieldProps = {
  id?: string;
  containerId?: string;
  name?: string;
  label?: string;
  ariaLabel?: string;
  helperText?: ReactNode;
  cornerHelperText?: ReactNode;
  topHelperText?: ReactNode;
  tooltip?: ReactNode;
  error?: boolean | string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  controlRef?: Ref<unknown>;
  FormControlComponent?: ElementType;
  FormLabelProps?: FormLabelProps;
  FormHelperTextProps?: FormHelperTextProps;
};

/** @deprecated - Use {@link FormControl} and compose (as required) with {@link FormLabel}, {@link HelperTooltip} {@link FormHelperText} and {@link FormErrorText} instead */
export const FormField = ({
  id: inId,
  containerId,
  name,
  label,
  ariaLabel,
  helperText,
  cornerHelperText,
  topHelperText,
  tooltip,
  error,
  required,
  disabled,
  fullWidth,
  loading,
  controlRef,
  children,
  FormControlComponent = 'fieldset',
  FormLabelProps,
  FormHelperTextProps,
  ...props
}: PropsWithChildren<FormFieldProps>): ReactElement => {
  const id = inId || name;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const topHelperTextId =
    topHelperText && id ? `${id}-top-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <FormControl
      variant="standard"
      id={containerId}
      name={name}
      aria-label={ariaLabel}
      required={required}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      ref={controlRef}
      component={FormControlComponent}
      {...props}
    >
      {label || tooltip || cornerHelperText || topHelperText ? (
        <Stack
          direction="row"
          gap={2}
          justifyContent={!label ? 'flex-end' : 'space-between'}
          marginBottom={!topHelperText ? 1 : 0}
        >
          {label || tooltip || topHelperText ? (
            <span>
              {label ? (
                <FormLabel
                  id={inputLabelId}
                  htmlFor={id}
                  {...FormLabelProps}
                  sx={{
                    ...FormLabelProps?.sx,
                    fontSize: 'body1.fontSize',
                    marginRight: tooltip ? 1 : undefined,
                  }}
                >
                  {label}
                </FormLabel>
              ) : null}
              {tooltip ? <HelperTooltip tooltip={tooltip} /> : null}
            </span>
          ) : null}
          {cornerHelperText ? (
            <FormHelperText sx={{ lineHeight: 1.4375, mt: 0 }}>
              {cornerHelperText}
            </FormHelperText>
          ) : null}
        </Stack>
      ) : null}
      {topHelperText ? (
        <FormHelperText
          component="div"
          {...FormHelperTextProps}
          id={topHelperTextId}
          error={false}
          sx={{ mt: 0, mb: 1, ...FormHelperTextProps?.sx }}
        >
          {topHelperText}
        </FormHelperText>
      ) : null}
      {loading ? <CircularProgress sx={{ mt: 1 }} /> : children}
      {!!error && typeof error === 'string' ? (
        <FormHelperText
          error
          component="div"
          role="alert"
          sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }}
        >
          <FaIcon icon={faTriangleExclamation} /> {error}
        </FormHelperText>
      ) : null}
      {helperText ? (
        <FormHelperText
          component="div"
          {...FormHelperTextProps}
          id={helperTextId}
          error={false}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
