import { route$ } from './utils';

const KNOWLEDGE_BASE = 'https://support.shuttlerock.com';

/**
 * Make sure to mirror this route with creative-foundation/src/routes as
 * typing in the route$ function is deduced from the creative-foundation/src/routes structure
 * */
export const Routes = {
  Home: route$('/'),
  Admin: {
    BRANDS_REDIRECT: route$('/brands'),
    BRAND_PROFILE_REDIRECT: route$('/brandProfile'),
    CLIENTS: route$('/client'),
    CLIENT_USERS: route$('/client/:integrationKey/users'),
    CLIENT_BRANDS: route$('/client/:integrationKey/brands'),
    EDIT_CLIENT: route$('/client/:integrationKey'),
    INVITE_CLIENT_USER: route$('/client/:integrationKey/invite'),
    NEW_CLIENT: route$('/client/new'),
    USERS: route$('/users'),
    ACCOUNT_INSIGHTS: route$('/accountInsights'),
  },
  Auth: {
    ACCEPT_INVITE: route$('/invitations/:invitationId'),
    COMPLETE_INVITE: route$('/invitations/:invitationId/complete'),
    PROFILE: route$('/profile'),
    SignIn: route$('/signin'),
    Callback: route$('/callback'),
    SignOut: route$('/signout'),
    SIGN_UP: route$('/signup'),
  },
  Creator: {
    Admin: '/creator/admin',
    Brief: route$('/creator/briefs/:ugcOrderKey'),
    Profile: '/creator/profile/:creatorKey',
    // @deprecated Creator signup page has been deprecated in favour of using React Admin pages to signup
    Signup: '/creator/signup/deprecated',
    SignupSuccess: 'success',
  },
  Customer: {
    BrandProfile: route$('/brands/:integrationKey/profile'),
  },
  Delivery: {
    DELIVERY_BASE: route$('/projects/:projectKey/delivery'),
    get ALL_CREATIVES() {
      return route$(`${this.DELIVERY_BASE}/creatives` as const);
    },
    get CONCEPTS() {
      return route$(`${this.DELIVERY_BASE}/concepts` as const);
    },
    get CONCEPT_CREATIVES() {
      return route$(
        `${this.DELIVERY_BASE}/concepts/:conceptKey/creatives` as const,
      );
    },
    get CONCEPT_VARIATIONS() {
      return route$(
        `${this.DELIVERY_BASE}/concepts/:conceptKey/variations` as const,
      );
    },
    get VARIATION_CREATIVES() {
      return route$(
        `${this.DELIVERY_BASE}/concepts/:conceptKey/variations/:variationKey/creatives` as const,
      );
    },
    NEW_VARIATION: route$('/projects/:projectKey/new-variation/:conceptKey'),
  },
  External: {
    ContactSupport: `https://share.hsforms.com/1lir9wLooSu6BCcI6XRe71g1rayn`,
    KnowledgeBase: KNOWLEDGE_BASE,
    SHUTTLEROCK_IMAGINE_SUPPORT: `${KNOWLEDGE_BASE}/shuttlerock-imagine`,
    TURNAROUND_TIME: `${KNOWLEDGE_BASE}/en/articles/9880884-turnaround-times`,
    AI_INFO_SUPPORT: `${KNOWLEDGE_BASE}/en/articles/9840914-ai-enhanced-creative`,
  },
  Projects: {
    All: route$('/projects/all'),
    New: '/?newProject=%7B"open"%3Atrue%7D',
    PROJECTS: route$('/projects'),
    DRAFT_PROJECTS: route$('/projects/drafts'),
    ACTIVE_PROJECTS: route$('/projects/active'),
    COMPLETED_PROJECTS: route$('/projects/complete'),
    UPLOAD_DELIVERABLES: route$('/projects/:projectKey/deliverables/upload'),
    PRODUCTION_BRIEF_PATH: route$('/projects/:projectKey/production'),
    PROJECT_BRIEF: route$('/projects/:projectKey/brief'),
    BRAND_PROFILE: route$('brand-profile/:brandKey'),
    CONCEPT_DETAILS: route$('concepts/:conceptKey'),
    UGC_CONCEPT_DETAILS: route$('concepts/:conceptKey/ugc'),
  },
  Revision: {
    COMPARE_VERSIONS: route$('/projects/:projectKey/review/compare'),
    CREATIVE: route$('/projects/:projectKey/review/:creativeKey?'),
    CREATIVE_FOR_SPECIFICATION_KEY: route$(
      '/projects/:projectKey/review/specification/:specificationKey',
    ),
    SELECTED_REVISION: route$(
      '/projects/:projectKey/review/:creativeKey?/revision/:revisionSequenceId',
    ),
  },
  SalesTools: {
    PROJECT_SHOWCASE: route$('/projects/completed'),
  },
} as const;
