import { ReactElement, forwardRef } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as BaseProps,
  SxProps,
  Theme,
} from '@mui/material';
import { merge } from 'lodash';
import { Option } from '../../../types/option';

export type ToggleButtonGroupProps = BaseProps & {
  name: string;
  options: Option[];
};

const BASE_STYLES: SxProps<Theme> = {
  gap: 2,
  flexWrap: { xs: 'wrap', md: 'no-wrap' },
  justifyContent: { xs: 'center', md: 'space-between' },
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: 1,
    },
    '&:first-of-type': {
      borderRadius: 1,
    },
  },
  '& .MuiToggleButton-root': {
    // Transparent border to prevent "popping" of button content when selecting
    border: '1px solid transparent',
    textTransform: 'none',
    color: (theme) => theme.palette.text.primaryAlt,
    backgroundColor: (theme) => theme.palette.background.default,
    '&.Mui-selected': {
      color: (theme) => theme.palette.primary.main,
      border: (theme) => `1px solid ${theme.palette.primary.main}`,
      backgroundColor: 'inherit',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: (theme) => theme.palette.background.secondary,
        },
      },
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme) => theme.palette.background.secondary,
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: (theme) => theme.palette.background.default,
      },
    },
  },
  '& .MuiToggleButton-sizeLarge': {
    typography: 'h2',
    minWidth: 56,
    height: 56,
  },
  '& .MuiToggleButton-sizeSmall': {
    typography: 'body1',
    // minWidth: 56,
    height: 36,
  },
};

export const ToggleButtonGroup = forwardRef(
  (
    { name, value, options, sx, ...props }: ToggleButtonGroupProps,
    ref,
  ): ReactElement => {
    const styles = merge({}, BASE_STYLES, sx);

    return (
      <MuiToggleButtonGroup ref={ref} value={value} sx={styles} {...props}>
        <input type="hidden" name={name} value={value} />
        {options.map(({ value: optValue, label }) => (
          <ToggleButton
            key={[optValue, label].filter(Boolean).join('-')}
            value={optValue}
          >
            {label}
          </ToggleButton>
        ))}
      </MuiToggleButtonGroup>
    );
  },
);
