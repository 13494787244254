import { PropsWithChildren, ReactElement, useEffect } from 'react';
import {
  json,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
} from 'react-router-dom';
import { Routes } from '@shuttlerock/router';
import { QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider as ApolloContext } from '@apollo/client';
import { getUserData, User } from '@creative-foundation/auth';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { ConfigService } from '@shuttlerock/configuration';
import { QueryParamProvider } from '@shuttlerock/use-query-params';
import {
  ClientAccountProvider,
  getUserClientAccount,
} from '@shuttlerock/client-account-context';
import type { ClientAccountContext } from '@shuttlerock/client-account-context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIdentifyLoggingUser } from '@shuttlerock/observability';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { useGlobalTheme, SnackbarProvider } from '@shuttlerock/mui-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HtmlTitle } from '@creative-foundation/shell-components';
import { AutoUpdate } from './components/AutoUpdate/AutoUpdate';
import { GraphQLClient } from './graphql/client';
import { Analytics } from './components/Analytics/Analytics';
import { ErrorBoundaryFallback } from './components/ErrorPage/ErrorBoundaryFallback';
import '@shuttlerock/i18n';

// 3rd party css
import '@uppy/dashboard/dist/style.css';
import '@uppy/core/dist/style.css';
import { flags } from './feature/flags';
import { queryClient } from './data/queryClient';

type LoaderResponse = {
  user: User | undefined;
  clientAccountContext: ClientAccountContext;
};

const AUTH_ROUTES: string[] = [
  Routes.Auth.SignIn,
  Routes.Auth.SignOut,
  Routes.Auth.Callback,
];

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  if (AUTH_ROUTES.includes(url.pathname)) {
    return json({ user: undefined, clientAccountContext: {} });
  }

  const data = await getUserData(request);
  const user = data?.user;
  const clientAccountContext = await getUserClientAccount(GraphQLClient, user);

  const response: LoaderResponse = { user, clientAccountContext };

  return json(response);
}

function logAppVersion() {
  const version = ConfigService.APP_VERSION();
  // eslint-disable-next-line no-console
  console.info(`[Shuttlerock Cloud] version: ${version}`);
}

// Set 100% height in throughout App/Shell to allow child apps to use 100% height.
const fullHeightStyle = { height: '100%' };
const heightStyles = (
  <GlobalStyles
    styles={{
      html: fullHeightStyle,
      body: fullHeightStyle,
      '#root': fullHeightStyle,
    }}
  />
);

type HelmetScript = {
  id?: string;
  type?: string;
  src?: string;
  'data-publickey'?: string;
};

const AnalyticsScript = (): HelmetScript | undefined => {
  if (ConfigService.SEGMENT_WRITE_KEY() && process.env.NODE_ENV !== 'test') {
    return {
      id: 'segment-analytics',
      type: 'text/javascript',
      src: `${ConfigService.APP_URL()}/scripts/analytics.js`,
      'data-publickey': ConfigService.SEGMENT_WRITE_KEY(),
    };
  }
  return undefined;
};

// eslint-disable-next-line import/no-default-export
export const Layout = ({ children }: PropsWithChildren): ReactElement => {
  const { theme } = useGlobalTheme();
  const autoUpdateInterval = ConfigService.AUTO_UPDATE_INTERVAL() || false;
  const script = [AnalyticsScript()].filter((s) => s !== undefined);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {heightStyles}
      <SnackbarProvider>
        <HelmetProvider>
          <HtmlTitle>Shuttlerock</HtmlTitle>
          <Helmet script={script} />
          {children}
        </HelmetProvider>
        {typeof autoUpdateInterval === 'number' ? (
          <AutoUpdate fetchInterval={autoUpdateInterval} />
        ) : null}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export const Root = () => {
  const data = useLoaderData() as LoaderResponse;
  useIdentifyLoggingUser(data.user);
  useEffect(() => logAppVersion(), []);

  return (
    <Layout>
      <QueryClientProvider client={queryClient}>
        <ApolloContext client={GraphQLClient}>
          <LDProvider clientSideID="" ldClient={flags.client}>
            <QueryParamProvider>
              <ClientAccountProvider value={data?.clientAccountContext}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Analytics />
                  <Outlet />
                </LocalizationProvider>
              </ClientAccountProvider>
            </QueryParamProvider>
          </LDProvider>
        </ApolloContext>
      </QueryClientProvider>
    </Layout>
  );
};

// eslint-disable-next-line import/no-default-export
export default Root;

export const ErrorBoundary = () => {
  return (
    <Layout>
      <ErrorBoundaryFallback />
    </Layout>
  );
};
