import { ReactElement, useMemo } from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import { nanoid } from 'nanoid';

type Id = { id: string };
const createIdArray = (length: number): Id[] =>
  Array.from({ length }, () => ({ id: nanoid() }));

const width = (): string => {
  const n = Math.random();
  return n < 0.75 ? width() : `${Math.round(n * 100)}%`;
};

export const SkeletonCell = (): ReactElement => (
  <Skeleton animation="wave" width={width()} />
);

export type SkeletonRowProps = {
  columns: number;
  hover?: boolean;
};

export const SkeletonRow = ({
  columns,
  hover,
}: SkeletonRowProps): ReactElement => {
  const cols = useMemo(() => createIdArray(columns), [columns]);

  return (
    <TableRow hover={hover}>
      {cols.map((col) => (
        <TableCell key={col.id}>
          <SkeletonCell />
        </TableCell>
      ))}
    </TableRow>
  );
};

export type SkeletonRowsProps = {
  loading: boolean;
  columns: number;
  pageSize?: number;
  hover?: boolean;
};

const DEFAULT_PAGE_SIZE = 30;

export const SkeletonRows = ({
  loading,
  columns,
  hover,
  pageSize = DEFAULT_PAGE_SIZE,
}: SkeletonRowsProps): ReactElement | null => {
  const rows = useMemo(() => createIdArray(pageSize), [pageSize]);

  return loading ? (
    <>
      {rows.map((row) => (
        <SkeletonRow key={row.id} columns={columns} hover={hover} />
      ))}
    </>
  ) : null;
};
