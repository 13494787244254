import {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { throttle } from 'lodash';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
import { useResizeObserver } from '@react-hookz/web';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

export const ScrollButton = ({
  direction,
  disabled,
  onClick,
}: {
  direction: 'left' | 'right';
  disabled?: boolean;
  onClick: () => void;
}): ReactElement => {
  return (
    <IconButton
      aria-label={`scroll-${direction}`}
      sx={{
        display: { xs: 'none', sm: 'flex' },
        zIndex: 1,
        backgroundColor: (theme) => theme.palette.grey[50],
        color: (theme) => theme.palette.grey[900],
        position: 'absolute',
        width: '42px',
        height: '42px',
        top: 'calc(50% - (42px / 2))',
        ...(direction === 'left' ? { left: '0px' } : { right: '0px' }),
        boxShadow: (theme) => theme.shadows[2],
        ':hover': {
          backgroundColor: (theme) => theme.palette.grey[50],
          opacity: disabled ? 0 : 1,
        },
        transition: (theme) =>
          [
            `opacity ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.standard}ms 0ms`,
            disabled
              ? `visibility ${theme.transitions.easing.easeInOut} 0ms ${theme.transitions.duration.standard}ms`
              : undefined,
          ]
            .filter((x) => x)
            .join(),
        opacity: disabled ? 0 : 0.8,
      }}
      onClick={onClick}
    >
      <FaIcon icon={direction === 'left' ? faChevronLeft : faChevronRight} />
    </IconButton>
  );
};

export type ScrollableProps = {
  children: ReactElement;
  sx?: SxProps<Theme>;
};

export const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>(
  ({ children, sx }, ref): ReactElement => {
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const scrollDivRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => scrollDivRef.current as HTMLDivElement);

    const updateDisplayScrollButtons = useMemo(
      () =>
        throttle(() => {
          const clientWidth = scrollDivRef.current?.clientWidth;
          const scrollWidth = scrollDivRef.current?.scrollWidth;
          const offset = scrollDivRef.current?.scrollLeft;

          if (clientWidth && scrollWidth && offset != null) {
            setShowLeftButton(offset > 0);
            setShowRightButton(offset + clientWidth < scrollWidth);
          }
        }, 250),
      [],
    );

    useResizeObserver(scrollDivRef, updateDisplayScrollButtons);
    useResizeObserver(contentRef, updateDisplayScrollButtons);

    const handleScroll = (direction: 'left' | 'right') => () => {
      let width = scrollDivRef.current?.clientWidth;
      if (!width) return;

      width = Math.max(width - 100, width * 0.75);
      if (direction === 'left') width = -width;

      scrollDivRef.current?.scrollBy({ left: width, behavior: 'smooth' });
    };

    return (
      <Box
        sx={{
          position: 'relative',
          maxWidth: '100%',
          ...sx,
        }}
      >
        <ScrollButton
          direction="left"
          onClick={handleScroll('left')}
          disabled={!showLeftButton}
        />
        <ScrollButton
          direction="right"
          onClick={handleScroll('right')}
          disabled={!showRightButton}
        />
        <Box
          ref={scrollDivRef}
          onScroll={updateDisplayScrollButtons}
          sx={{
            width: '100%',
            overflow: 'scroll',
            // Chrome, Safari:
            '&::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
            scrollbarWidth: 'none' /* Firefox */,
          }}
        >
          <Box
            ref={contentRef}
            sx={{
              width: { xs: 'inherit', sm: 'max-content' },
              height: 'max-content',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  },
);
