import {
  ChangeEventHandler,
  FocusEventHandler,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  forwardRef,
  ChangeEvent,
} from 'react';
import {
  Checkbox as MuiCheckbox,
  formControlLabelClasses,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FormField, FormFieldProps } from '../FormField/FormField';
import { FormControlLabel, HelperTooltip } from '../FormControl/FormControl';

/**
 * @deprecated - Use {@link CheckboxSingle} instead
 */
export interface CheckboxProps extends FormFieldProps {
  id?: string;
  name: string;
  checkboxLabel: string;
  readOnly?: boolean;
  value: boolean;
  onChange: (value: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
}

/**
 * @deprecated - Use {@link CheckboxSingle} instead
 */
export const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  checkboxLabel,
  readOnly,
  value,
  onChange,
  onBlur,
  ...formFieldProps
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange(event?.target?.checked || false, event),
    [onChange],
  );

  const handleBlur = !readOnly ? onBlur : undefined;

  return (
    <FormField {...formFieldProps} name={name} id={id}>
      <FormGroup onBlur={handleBlur}>
        <FormControlLabel
          data-testid={`${name}-option`}
          label={checkboxLabel}
          onBlur={handleBlur}
          sx={readOnly ? { cursor: 'default' } : undefined}
          control={
            <MuiCheckbox
              name={name}
              readOnly={readOnly}
              disableRipple={readOnly}
              checked={value}
              onChange={!readOnly ? handleChange : undefined}
            />
          }
        />
      </FormGroup>
    </FormField>
  );
};

export type CheckboxSingleProps = {
  id?: string;
  name: string;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  tooltip?: ReactNode;
  size?: 'small' | 'medium';
  /**
   * To use as an "uncontrolled" form element the checkbox must be given a `string`
   * value, which will determine the checked state.
   *
   * @example - Checked by Default
   * const { register } = useForm({ defaultValues: { myCheckbox: 'myValue' } });
   * <CheckboxSingle {...register('myCheckbox')} value="myValue" />;
   */
  value?: string;
  /**
   * Allows checked state to be "controlled", if a `boolean` value is required for instance.
   *
   * @example - Controlled Form
   * const { control } = useForm(defaultValues: { myCheckbox: true });
   * <Controller
   *   name="myCheckbox"
   *   control={control}
   *   render={({ field: { name, ref, value, onChange, onBlur } }) => (
   *     <CheckboxSingle
   *       ref={ref}
   *       name={name}
   *       checked={value}
   *       onBlur={onBlur}
   *       onChange={(e) => onChange(e.target.checked)}
   *     />
   *   )}
   * />
   */
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler;
};

export const CheckboxSingle = forwardRef<HTMLInputElement, CheckboxSingleProps>(
  (
    {
      id,
      name,
      label,
      disabled,
      readOnly,
      tooltip,
      size,
      value,
      checked,
      onBlur,
      onChange,
    },
    ref,
  ): ReactElement => {
    const handleBlur = !readOnly ? onBlur : undefined;

    const typographyClassName = [
      formControlLabelClasses.label,
      disabled && formControlLabelClasses.disabled,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{ '.MuiHelperTooltip-icon': { mb: 0 } }}
      >
        <FormControlLabel
          id={id}
          disableTypography
          disabled={disabled}
          size={size}
          label={
            <Typography className={typographyClassName} variant="inputLabel">
              {label}
            </Typography>
          }
          data-testid={id}
          onBlur={handleBlur}
          sx={{
            ...(tooltip && { mr: 0 }),
            ...(readOnly && { cursor: 'default' }),
          }}
          control={
            <MuiCheckbox
              name={name}
              value={value}
              checked={checked}
              disabled={disabled}
              readOnly={readOnly}
              disableRipple={readOnly}
              inputRef={ref}
              onChange={!readOnly ? onChange : undefined}
              onBlur={handleBlur}
            />
          }
        />
        {tooltip ? <HelperTooltip tooltip={tooltip} /> : null}
      </Stack>
    );
  },
);
