/* eslint-disable @typescript-eslint/no-use-before-define */
import { ConfigService } from '@shuttlerock/configuration';
import { FetchUserError } from './errors';

type UserData = {
  id: string;
  integrationKey: string;
};

const USER_DATA_QUERY = `
query Me {
  user {
    id
    integrationKey
  }
}
`.trim();

/**
 * Attempts to query the Rails API for the current users integration key (and legacy ID).
 * @internal - Not to be used outside `@creative-foundation/auth`
 */
export async function getUserData(
  accessToken: string,
): Promise<UserData | undefined> {
  try {
    const response = await fetch(`${ConfigService.ORDER_BASE_URL()}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ query: USER_DATA_QUERY }),
    });

    if (!response.ok) {
      throw new FetchUserError(`${response.status} (${response.statusText})`);
    }

    const { data } = await response.json();
    if (!isUserData(data?.user)) {
      return undefined;
    }

    return data.user;
  } catch (e) {
    if (e instanceof FetchUserError) {
      throw e;
    }
    if (e instanceof Error) {
      throw new FetchUserError(e.message);
    }
    console.error(e);
    throw new FetchUserError(`An unexpected error occurred: ${e}`);
  }
}

function isUserData(input: unknown): input is UserData {
  function hasShape(x: unknown): x is { id: unknown; integrationKey: unknown } {
    return (
      typeof x === 'object' && x !== null && 'id' in x && 'integrationKey' in x
    );
  }

  return (
    hasShape(input) &&
    typeof input.id === 'string' &&
    typeof input.integrationKey === 'string'
  );
}
